.nav-menu {
    display:flex;
}

.headernav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height:10vh;
    z-index: 4;
    top: 0;
    background-color:#1212129d;
    border-bottom: 1px solid #12121252;
    
    

   
    
   
}



.nav-menu li {
    padding: 0 2rem;
   
}



.nav-menu p {
    font-size:1.2rem;
    padding: 0 1rem;
}






.hamburger { 
    display: none;
   
   
}

.ContactBtn {
    font-size: 1.2rem;
    margin-right: 3rem;
    padding-left: 15px;
    padding-right: 15px;
    padding-top:3px;
    padding-bottom: 3px;
    border: 2px solid #fefdfd;
    border-radius: 10px;


}
.ContactBtn2 {
    font-size: 1.2rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top:8px;
    padding-bottom: 8px;
    border: 2px solid #fefdfd;
    border-radius: 10px;


}
.active-link {
    text-decoration: underline;
  }
  






@keyframes fadeIn {
    from { opacity:0}
    to {opacity: 0.8}
}

@media screen and (max-width: 1040px) {

    .nav-menu {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        padding:1rem;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        
    }

    .nav-menu.active {
        left: 0;
    }
    .nav-menu li {
        padding: 1rem 0;
    }
   
    .hamburger {
        display: initial;
        margin-right: 1rem;
    }
    
   
    
}