.contentg {
    position: absolute;
    top: 10%;
    height: 100%;
    width: 100%;
}
.contain h1 {
    font-size: 2rem;
    margin-top: 4rem;
    color: #fefdfd;
  

    
}

.tabedits{
    opacity: 0.95;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;

}

.contain h1 {
    text-align: center;
    align-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
   
    
}
.contain {
    margin-bottom: 2rem;
}

@media screen and (max-width: 500px) {

    .tabedits{
        opacity: 0.85;
        margin-top: 3rem;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
    
    }

}



