
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

 *{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter";
 }

 body {

    background: #121212;
    background-size: 400% 400%;
 
    height: 100vh;
  }
  .home {
    /* Add the animation styles only when the home class is present */
    background: #121212;
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    height: 100vh;
  }
  
  
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

  
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  
 

 h1, h4, p, a {
  color: white;
  text-decoration: none;
 }

 ul {
  list-style-type: none;
 }

 .btn {
  width: 100px;
  height: 50px;
  padding: 12px 32px;
  background: rgba(227, 185, 46, 0.417);
  color: rgb(233, 233, 233);
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
  
 }
.btn-light {
  width: 100px;
  height: 50px;
  padding: 12px 32px;
  border: 1px solid white;
  background: transparent;
  font-weight: 600;
  color: white;
}

.btn:hover {
  background: grey;
  color: white;
  transition: 0.3s;
}
