
.work-container{
    margin-top: 2rem;
    max-width: 100%;
}


.project-container {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:40px;
    padding-bottom: 5rem;
    margin-left: 7rem;
    margin-right: 2rem;
    
}
.project-card {
    background: #121212;
    border-radius: 1.5rem;
    box-shadow: 0 5px 5px rgba(255, 0, 212, 0.119);
    box-shadow: 1px 4px 4px #3E2EB6;
   
   
    

   
}

.arrow {
    transform: rotate(-45deg);

}

.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
    
   
}


.project-card img {
    width: 100%;
    height: 17rem;
    opacity: 0.8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    

}

.project-title {
    color: white;
    padding: 1rem 0;
    margin-left:1rem;
    text-align: left;
    font-size: 1.5rem;
 
}

.pro-details p{
    color: #fefdfd;
    font-size: 1rem;
    text-align: left;
    margin-left:1rem;
    margin-right: 2rem;
   
    
}

.pro-btns {
   
    display: flex;
    justify-content: left;
    margin-left: 1rem;

    
   
    
 
    
    
   
}

.pro-btns .tbtn {
    color:#fefdfd;
    border-bottom: 3px #3E2EB6 solid;
    margin-right: 3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width:4rem;
    display: flex;
    justify-content: space-between;
    
    
   
    
  
}

.tags {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #3E2EB6;
    border-radius: 1rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
    font-size: 0.8rem;
}



@media screen and (max-width: 1000px) {
    .project-container {
        margin-left: 10rem;
        margin-right: 10rem;
        grid-template-columns: 1fr;
        padding: 0;
        margin-bottom: 8rem;
      
    }

}

@media screen and (max-width: 740px) {
    .project-container {
        margin-left: 1.3rem;
        margin-right: 1rem;
        grid-template-columns: 1fr;
        padding: 0;
        margin-bottom: 5rem;
      
    }

}