
.whole{
  margin-top: 10rem;
}

.container4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 7rem;
    padding-right:2rem;
 
}
  .text-container4  {

    width: 50rem;
    font-size: 1rem;

  }

  .text-container4 h1{
    font-size: 6rem;
    padding-right:15rem;


   
  }

  .text-container4 p {
    font-size: 1.5rem;



  }

  .text-container4 button {
    font-size: 1.5rem;
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background: #3E2EB6;
    border: none;
    color:#fefdfd;
    border-radius: 15px;
    margin-top: 2rem;
   

  }
  .image-container4 {

    align-items: center;
    padding-right: 4rem;


  }
  
  .image-container4 img {
    
    width: 100%;
    max-width: 325px; /* Adjust the maximum width as needed */
    border-radius: 70rem;
  

 
    
   

  }
  .myexp {
    margin-top: 6rem;

    display:flex;
    justify-content: space-between;
    padding-left: 7rem;
    padding-right:2rem;
  }
  .myexp h1 {
    color: #fefdfde8;
    font-size: 2.5rem;
    padding-bottom: 0.2rem;
    border-bottom: 4px solid #3E2EB6;
    width: 15rem;
   
  }

  .myexp p {
 
    padding: 1rem;
    font-size: 1rem;
   
   
  }

  
  .card-holder{
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
   grid-gap:40px;
   margin-bottom: 3rem;
   margin-top: 4rem;
   padding-left: 7rem;
    padding-right:2rem;
  
  
  }



.experience-card {
 position: relative;
    text-align: center; /* Optional: Center the content */
    color: #fefdfd;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1.5rem;
    background: #121212;
    border-radius: 1rem;
    box-shadow: 1px 4px 4px #3E2EB6;
    
  
}

.experience-card h2 {
    margin-bottom: 1rem; 
    margin-top:1rem;
}

.experience-card P{
  margin-top: 1rem;
  color:#6c5ed1;
  font-size: 1.2rem;
}








  

  @media screen and (max-width: 1000px) {
   

    .container4 {
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 6rem;
      padding: 1rem;
   

    }
  
    .text-container4 {

      text-align: center;
      width: 100%;
      

    }
    .image-container4{
     padding: 0;
    }
    .text-container4 h1 {
      margin-top: 1rem;
      font-size: 9vw; /* Adjust the value as needed */
      white-space: nowrap;
      overflow: hidden;
      padding-right: 0rem;
  }
  
    .text-container4 p {
      font-size: 1rem;
    }
   
     
   

    .card-holder{
      grid-template-columns: 1fr;
      padding: 0rem;
      margin: 2rem;
      margin-top:3rem ;
      
     
    
    }

    .myexp {
      padding: 0rem;
      text-align: center;
      align-items: center;
      flex-direction: column;
      display: flex;
      justify-content: none;
 

    }

   
    
    .myexp h1 {
      color: #fefdfde8;
      font-size: 2.5rem;
     text-align: center;
      border-bottom: 4px solid #3E2EB6;
      width: 15rem;
      margin-bottom: 1rem;
      
  
     
    }
  

    
    


    
   
  

    

}


  